import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { array_type, uint8_type, union_type, record_type, string_type, option_type, list_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Pagination_$reflection } from "../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { ScheduleHandlerLog_$reflection } from "../../../Logos.Shared/Domain/Entity/ScheduleHandler.fs.js";
import { Busy, Busy_$reflection } from "../SharedView.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { AppRootModule_getSelectedOrgId } from "../../Infrastructure/AppRoot.fs.js";
import { paginationControl, previousPage, firstPage, nextPage, getDefaultPagination } from "./Pagination.fs.js";
import { ofArray, singleton as singleton_1, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { createObj, equals } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { DateTime_dateTimeToStringWithDayName, HttpUtil_saveByteArray } from "../../AppDomain/Util.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { Daisy_progressState, Daisy_error } from "./Component.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { exists, singleton as singleton_2, map, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { toLocalTime } from "../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { toString } from "../../fable_modules/fable-library-js.4.19.3/TimeOnly.js";
import { toArray } from "../../fable_modules/fable-library-js.4.19.3/Option.js";

export class State extends Record {
    constructor(ParentId, CurrentPagination, NextPagination, ScheduleHandlerLogs, IsDownloadingPdf, IsBusy, Errors) {
        super();
        this.ParentId = ParentId;
        this.CurrentPagination = CurrentPagination;
        this.NextPagination = NextPagination;
        this.ScheduleHandlerLogs = ScheduleHandlerLogs;
        this.IsDownloadingPdf = IsDownloadingPdf;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("ScheduleHandlerLog.State", [], State, () => [["ParentId", class_type("System.Guid")], ["CurrentPagination", Pagination_$reflection()], ["NextPagination", Pagination_$reflection()], ["ScheduleHandlerLogs", list_type(ScheduleHandlerLog_$reflection())], ["IsDownloadingPdf", option_type(class_type("System.Guid"))], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GotoPreviousPage", "GotoNextPage", "GotoFirstPage", "GetScheduleHandlerLogsResponse", "GetScheduleHandlerLogsAttachment", "GetScheduleHandlerLogsAttachmentResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("ScheduleHandlerLog.Msg", [], Msg, () => [[], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(ScheduleHandlerLog_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(ScheduleHandlerLog_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(uint8_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", array_type(uint8_type)]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getScheduleHandlerLogsCmd(getScheduleHandlerLogsFunc, state) {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => getScheduleHandlerLogsFunc(AppRootModule_getSelectedOrgId(), state.ParentId, state.NextPagination)));
}

export function getScheduleHandlerLogsAttachmentCmd(getScheduleHandlerLogsAttachmentFunc, logId) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => getScheduleHandlerLogsAttachmentFunc(AppRootModule_getSelectedOrgId(), logId)));
}

export function init(getScheduleHandlerLogsFunc, parentId) {
    let state;
    const pagination = getDefaultPagination();
    state = (new State(parentId, pagination, pagination, empty(), undefined, new Busy(0, []), empty()));
    return [state, getScheduleHandlerLogsCmd(getScheduleHandlerLogsFunc, state)];
}

function changePage(getScheduleHandlerLogsFunc, state, f) {
    const currentState = state;
    const newState = new State(state.ParentId, state.CurrentPagination, f(state.CurrentPagination), state.ScheduleHandlerLogs, state.IsDownloadingPdf, new Busy(1, []), state.Errors);
    if (equals(currentState, newState)) {
        return [currentState, Cmd_none()];
    }
    else {
        return [newState, getScheduleHandlerLogsCmd(getScheduleHandlerLogsFunc, newState)];
    }
}

export function update(getScheduleHandlerLogsFunc, attachmentFileName, getScheduleHandlerLogsAttachmentFunc, msg, state) {
    switch (msg.tag) {
        case 1:
            return changePage(getScheduleHandlerLogsFunc, state, nextPage);
        case 2:
            return changePage(getScheduleHandlerLogsFunc, state, firstPage);
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ParentId, state.CurrentPagination, state.NextPagination, state.ScheduleHandlerLogs, state.IsDownloadingPdf, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.ParentId, state.NextPagination, state.NextPagination, msg.fields[0].fields[0], state.IsDownloadingPdf, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 4:
            return [new State(state.ParentId, state.CurrentPagination, state.NextPagination, state.ScheduleHandlerLogs, msg.fields[0], state.IsBusy, state.Errors), getScheduleHandlerLogsAttachmentCmd(getScheduleHandlerLogsAttachmentFunc, msg.fields[0])];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ParentId, state.CurrentPagination, state.NextPagination, state.ScheduleHandlerLogs, undefined, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                HttpUtil_saveByteArray(msg.fields[0].fields[0], `${attachmentFileName}`, "application/octet-stream");
                return [new State(state.ParentId, state.CurrentPagination, state.NextPagination, state.ScheduleHandlerLogs, undefined, state.IsBusy, state.Errors), Cmd_none()];
            }
        case 6:
            return [new State(state.ParentId, state.CurrentPagination, state.NextPagination, state.ScheduleHandlerLogs, state.IsDownloadingPdf, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return changePage(getScheduleHandlerLogsFunc, state, previousPage);
    }
}

export function IndexView(props) {
    let children, elems_2, children_5, children_3, children_25;
    let patternInput;
    const init_1 = init(props.GetScheduleHandlerLogsFunc, props.ParentId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, (msg, state) => update(props.GetScheduleHandlerLogsFunc, props.AttachmentFileName, props.GetScheduleHandlerLogsAttachmentFunc, msg, state), (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const children_29 = ofArray([Daisy_error(state_1.Errors), (children = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children)),
    })), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["w-full"])], (elems_2 = [(children_5 = singleton_1((children_3 = ofArray([createElement("th", {
        width: 15 + "%",
        children: "Date",
    }), createElement("th", {
        width: 15 + "%",
        children: "Schedule Time",
    }), createElement("th", {
        width: 60 + "%",
        children: "Schedule Details",
    }), createElement("th", {
        width: 10 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), (children_25 = toList(delay(() => map((log) => {
        let children_19, children_21;
        const children_23 = ofArray([createElement("td", {
            children: DateTime_dateTimeToStringWithDayName(toLocalTime(log.LastScheduleActionedDateTime)),
        }), createElement("td", {
            children: toString(log.ScheduleTime),
        }), (children_19 = toList(delay(() => {
            let children_17, children_15, children_9, children_7, children_13, children_11;
            const x = log.ScheduleActionType.fields[0];
            return singleton_2((children_17 = singleton_1((children_15 = ofArray([(children_9 = ofArray([createElement("td", {
                children: "Pdf Generated: ",
            }), (children_7 = toList(delay(() => (x.WasPdfGenerated ? singleton_2(createElement("i", {
                className: "fas fa-check",
                style: {
                    color: "green",
                },
            })) : singleton_2(createElement("i", {
                className: "fas fa-times",
                style: {
                    color: "red",
                },
            }))))), createElement("td", {
                children: reactApi.Children.toArray(Array.from(children_7)),
            }))]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children_9)),
            })), (children_13 = ofArray([createElement("td", {
                children: "Pdf Uploaded to Simpro: ",
            }), (children_11 = toList(delay(() => (x.IsPdfPostedToSimpro ? singleton_2(createElement("i", {
                className: "fas fa-check",
                style: {
                    color: "green",
                },
            })) : singleton_2(createElement("i", {
                className: "fas fa-times",
                style: {
                    color: "red",
                },
            }))))), createElement("td", {
                children: reactApi.Children.toArray(Array.from(children_11)),
            }))]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children_13)),
            }))]), createElement("tbody", {
                children: reactApi.Children.toArray(Array.from(children_15)),
            }))), createElement("table", {
                children: reactApi.Children.toArray(Array.from(children_17)),
            })));
        })), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_19)),
        })), (children_21 = toList(delay(() => {
            let elems_1;
            return log.ScheduleActionType.fields[0].WasPdfGenerated ? singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["btn btn-square btn-xs text-primary"])], (elems_1 = [createElement("i", {
                className: "fas fa-download",
                title: "Download",
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))]), ["disabled", exists((y) => (log.Id === y), toArray(state_1.IsDownloadingPdf))], ["onClick", (_arg_2) => {
                dispatch(new Msg(4, [log.Id]));
            }]])))) : singleton_2(react.createElement(react.Fragment, {}));
        })), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_21)),
        }))]);
        return createElement("tr", {
            children: reactApi.Children.toArray(Array.from(children_23)),
        });
    }, state_1.ScheduleHandlerLogs))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_25)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), paginationControl(false, state_1.CurrentPagination.CurrentPage, (_arg_3) => {
        dispatch(new Msg(0, []));
    }, (_arg_4) => {
        dispatch(new Msg(1, []));
    }, (_arg_5) => {
        dispatch(new Msg(2, []));
    })]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_29)),
    });
}

