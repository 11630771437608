import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { int32_type, list_type, option_type, record_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { DocumentTypeModule_toInt, DocumentTypeModule_ofInt, Document$, DocumentType$_$reflection, DocumentTag } from "../Entity/Document.fs.js";
import { map } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { toNullable, defaultArg, value } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";

export class DocumentTagDto extends Record {
    constructor(Id, OrganisationId, Name) {
        super();
        this.Id = Id;
        this.OrganisationId = OrganisationId;
        this.Name = Name;
    }
}

export function DocumentTagDto_$reflection() {
    return record_type("Domain.Dto.Document.DocumentTagDto", [], DocumentTagDto, () => [["Id", class_type("System.Guid")], ["OrganisationId", class_type("System.Guid")], ["Name", string_type]]);
}

export function DocumentTagDtoModule_toDocumentTag(tag) {
    return new DocumentTag(tag.Id, tag.OrganisationId, tag.Name);
}

export function DocumentTagDtoModule_ofDocumentTag(tag) {
    return new DocumentTagDto(tag.Id, tag.OrganisationId, tag.Name);
}

export class DocumentDto extends Record {
    constructor(Id, OrganisationId, OwnerId, Name, FileExtension, Expiry, Tags, DocumentType$, ModifiedBy, Modified) {
        super();
        this.Id = Id;
        this.OrganisationId = OrganisationId;
        this.OwnerId = OwnerId;
        this.Name = Name;
        this.FileExtension = FileExtension;
        this.Expiry = Expiry;
        this.Tags = Tags;
        this.DocumentType = DocumentType$;
        this.ModifiedBy = ModifiedBy;
        this.Modified = Modified;
    }
}

export function DocumentDto_$reflection() {
    return record_type("Domain.Dto.Document.DocumentDto", [], DocumentDto, () => [["Id", class_type("System.Guid")], ["OrganisationId", class_type("System.Guid")], ["OwnerId", class_type("System.Guid")], ["Name", string_type], ["FileExtension", option_type(string_type)], ["Expiry", option_type(class_type("System.DateTimeOffset"))], ["Tags", list_type(DocumentTagDto_$reflection())], ["DocumentType", DocumentType$_$reflection()], ["ModifiedBy", class_type("System.Guid")], ["Modified", class_type("System.DateTimeOffset")]]);
}

export function DocumentDtoModule_toDocument(document$) {
    return new Document$(document$.Id, document$.OrganisationId, document$.OwnerId, document$.Name, document$.FileExtension, document$.Expiry, document$.DocumentType, map(DocumentTagDtoModule_toDocumentTag, document$.Tags), document$.ModifiedBy, document$.Modified);
}

export function DocumentDtoModule_ofDocument(document$) {
    return new DocumentDto(document$.Id, document$.OrganisationId, document$.OwnerId, document$.Name, document$.FileExtension, document$.Expiry, map(DocumentTagDtoModule_ofDocumentTag, document$.Tags), document$.DocumentType, document$.ModifiedBy, document$.Modified);
}

export class DocumentRawDto extends Record {
    constructor(Id, OrganisationId, OwnerId, Name, FileExtension, Expiry, Tags, DocumentType$, ModifiedBy, Modified) {
        super();
        this.Id = Id;
        this.OrganisationId = OrganisationId;
        this.OwnerId = OwnerId;
        this.Name = Name;
        this.FileExtension = FileExtension;
        this.Expiry = Expiry;
        this.Tags = Tags;
        this.DocumentType = (DocumentType$ | 0);
        this.ModifiedBy = ModifiedBy;
        this.Modified = Modified;
    }
}

export function DocumentRawDto_$reflection() {
    return record_type("Domain.Dto.Document.DocumentRawDto", [], DocumentRawDto, () => [["Id", class_type("System.Guid")], ["OrganisationId", class_type("System.Guid")], ["OwnerId", class_type("System.Guid")], ["Name", string_type], ["FileExtension", string_type], ["Expiry", class_type("System.Nullable`1", [class_type("System.DateTimeOffset")])], ["Tags", list_type(DocumentTagDto_$reflection())], ["DocumentType", int32_type], ["ModifiedBy", class_type("System.Guid")], ["Modified", class_type("System.DateTimeOffset")]]);
}

export function DocumentRawDtoModule_toDocument(document$) {
    const Expiry = (document$.Expiry != null) ? value(document$.Expiry) : undefined;
    const Tags = map(DocumentTagDtoModule_toDocumentTag, document$.Tags);
    return new Document$(document$.Id, document$.OrganisationId, document$.OwnerId, document$.Name, document$.FileExtension, Expiry, DocumentTypeModule_ofInt(document$.DocumentType), Tags, document$.ModifiedBy, document$.Modified);
}

export function DocumentRawDtoModule_ofDocument(document$) {
    return new DocumentRawDto(document$.Id, document$.OrganisationId, document$.OwnerId, document$.Name, defaultArg(document$.FileExtension, ""), toNullable(document$.Expiry), map(DocumentTagDtoModule_ofDocumentTag, document$.Tags), DocumentTypeModule_toInt(document$.DocumentType), document$.ModifiedBy, document$.Modified);
}

