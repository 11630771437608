import { simproServiceProxy, holidayServiceProxy, invoiceServiceProxy, xeroServiceProxy, expenseServiceProxy, leavesServiceProxy, shiftServiceProxy, jobServiceProxy, skillServiceProxy, userServiceProxy, logMasterServiceProxy, vehicleServiceProxy, stripeServiceProxy, customerServiceProxy, organisationServiceProxy, appFetchResponseToResult, baseUrl, appFetch, documentServiceProxy, serviceProxy, getTokenAndUserRole, makeSecureRemoteCall, authServiceProxy } from "./Remoting.fs.js";
import { string_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { Convert_fromJson, Convert_serialize } from "../../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "../../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { DocumentRawDto_$reflection } from "../../../Logos.Shared/Domain/Dto/Document.fs.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { empty, singleton as singleton_1 } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { awaitPromise } from "../../fable_modules/fable-library-js.4.19.3/Async.js";
import { Guid_parse } from "../../../Logos.Shared/Util.fs.js";
import { SimpleJson_tryParse } from "../../fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B } from "../../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { TimesheetReportGroupedByModule_toString } from "../../../Logos.Shared/Domain/Entity/Shift.fs.js";

export class AuthService {
    constructor() {
    }
    GetOneTimeSignupCode(username) {
        return authServiceProxy.getOneTimeSignupCode(username);
    }
    GetOneTimeResetCode(username) {
        return authServiceProxy.getOneTimeResetCode(username);
    }
    RefreshToken(token, refreshToken) {
        return authServiceProxy.refreshToken(token, refreshToken);
    }
    ResetPassword(resetPassword) {
        return authServiceProxy.resetPassword(resetPassword);
    }
    SignIn(username, password) {
        return authServiceProxy.signIn(username, password);
    }
    Signup(signup) {
        return authServiceProxy.signup(signup);
    }
}

export function AuthService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.AuthService", undefined, AuthService);
}

export function AuthService_$ctor() {
    return new AuthService();
}

export class Service {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    GetMessage() {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return serviceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.GetMessage());
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function Service_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.Service", undefined, Service);
}

export function Service_$ctor_Z749FCB85(systemService, authService) {
    return new Service(systemService, authService);
}

export class DocumentService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    ScanBankStatement(selectedOrgId, filename, file) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.scanBankStatement(selectedOrgId, filename, file));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetUnprocessedScans() {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getUnprocessedScans());
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    DeleteSelectedScans(selectedScanResults) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.deleteScans(selectedScanResults));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SearchDocumentTags(selectedOrgId, documentTagSearchDetails, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.searchDocumentTags(selectedOrgId, documentTagSearchDetails, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AddDocumentTag(selectedOrgId, documentTag) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.addDocumentTag(selectedOrgId, documentTag));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpdateDocumentTag(selectedOrgId, documentTag) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.updateDocumentTag(selectedOrgId, documentTag));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetDocumentTag(selectedOrgId, documentId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getDocumentTag(selectedOrgId, documentId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetDocumentTags(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getDocumentTags(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SearchDocuments(selectedOrgId, documentSearchDetails, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.searchDocuments(selectedOrgId, documentSearchDetails, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetDocument(selectedOrgId, documentId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getDocument(selectedOrgId, documentId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AddDocument(selectedOrgId, document$, documentFile) {
        const this$ = this;
        return singleton.Delay(() => {
            const formData = new FormData();
            formData.append("document", Convert_serialize(document$, createTypeInfo(DocumentRawDto_$reflection())));
            formData.append("formFile", documentFile);
            const asyncResult_3 = appFetch(this$.systemService, "POST", `${baseUrl}Document/AddDocument?selectedOrgId=${selectedOrgId}`, singleton_1(new Types_RequestProperties(2, [formData])));
            return singleton.Bind(asyncResult_3, (input_1_2) => {
                let matchValue_1;
                const input_11 = input_1_2;
                if (input_11.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_11.fields[0]]));
                }
                else {
                    const response = input_11.fields[0];
                    if ((response.status) === 200) {
                        let asyncResult_1;
                        const input = awaitPromise(response.text());
                        asyncResult_1 = singleton.Bind(input, (x$0027) => singleton.Return(new FSharpResult$2(0, [x$0027])));
                        return singleton.Bind(asyncResult_1, (input_1_1) => {
                            let matchValue;
                            const input_6 = input_1_1;
                            if (input_6.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                            }
                            else {
                                const value_1_2 = Guid_parse((matchValue = SimpleJson_tryParse(input_6.fields[0]), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(string_type)) : (() => {
                                    throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
                                })()));
                                return singleton.Return(value_1_2);
                            }
                        });
                    }
                    else {
                        const value_1_4 = new FSharpResult$2(1, [singleton_1((matchValue_1 = SimpleJson_tryParse(response.statusText), (matchValue_1 != null) ? Convert_fromJson(matchValue_1, createTypeInfo(string_type)) : (() => {
                            throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
                        })()))]);
                        return singleton.Return(value_1_4);
                    }
                }
            });
        });
    }
    UpdateDocument(selectedOrgId, document$, documentFile) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.updateDocument(selectedOrgId, document$, documentFile));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetDocumentFile(selectedOrgId, documentId) {
        const this$ = this;
        return singleton.Delay(() => {
            let asyncResult_1;
            const input = appFetch(this$.systemService, "GET", `${baseUrl}Document/GetDocumentFile?selectedOrgId=${selectedOrgId}&documentId=${documentId}`, empty());
            asyncResult_1 = singleton.Bind(input, (x$0027) => {
                let value;
                const input_2 = x$0027;
                value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [appFetchResponseToResult(input_2.fields[0])])));
                return singleton.Return(value);
            });
            return singleton.Bind(asyncResult_1, (input_1_2) => {
                const input_6 = input_1_2;
                if (input_6.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                }
                else {
                    return input_6.fields[0];
                }
            });
        });
    }
    DeleteDocument(selectedOrgId, documentId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return documentServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.deleteDocument(selectedOrgId, documentId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function DocumentService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.DocumentService", undefined, DocumentService);
}

export function DocumentService_$ctor_Z749FCB85(systemService, authService) {
    return new DocumentService(systemService, authService);
}

export class OrganisationService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    UpsertOrganisation(selectedOrgId, isInsert, organisation) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return organisationServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.upsertOrganisation(selectedOrgId, isInsert, organisation));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetOrganisation(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return organisationServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getOrganisation(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetUserOrganisations() {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return organisationServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getUserOrganisations());
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetPurchasedServices(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return organisationServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getPurchasedServices(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetXeroClientId(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return organisationServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getXeroClientId(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SaveLogo(selectedOrgId, logo) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return organisationServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.saveLogo(selectedOrgId, logo));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetLogo(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const x_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return organisationServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getLogo(selectedOrgId));
            return singleton.Bind(x_1, (x$0027) => {
                let value;
                const input_2 = x$0027;
                value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [input_2.fields[0]])));
                return singleton.Return(value);
            });
        });
    }
}

export function OrganisationService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.OrganisationService", undefined, OrganisationService);
}

export function OrganisationService_$ctor_Z749FCB85(systemService, authService) {
    return new OrganisationService(systemService, authService);
}

export class CustomerService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    AddCustomersFromBankStatement(selectedOrgId, scanResultIds, addresses, customers) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return customerServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.addCustomersFromBankStatement(selectedOrgId, scanResultIds, addresses, customers));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AddNewCustomer(selectedOrgId, customer) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return customerServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.addNewCustomer(selectedOrgId, customer));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SearchCustomer(selectedOrgId, searchText, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return customerServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.searchCustomer(selectedOrgId, searchText, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetCustomer(selectedOrgId, customerId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return customerServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getCustomer(selectedOrgId, customerId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SaveCustomer(selectedOrgId, customer) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return customerServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.saveCustomer(selectedOrgId, customer));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    LoadAssessments(selectedOrgId, customerId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return customerServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.loadAssessments(customerId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    LoadAssessmentItems(selectedOrgId, assessmentId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return customerServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.loadAssessmentItems(assessmentId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SaveAssessmentItem(selectedOrgId, itemId, newValue) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return customerServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.saveAssessmentItem(itemId, newValue));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AssignScanToExistingCustomer(selectedOrgId, scanResultIds, selectedCustomerId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return customerServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.assignScanToExistingCustomer(scanResultIds, selectedCustomerId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function CustomerService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.CustomerService", undefined, CustomerService);
}

export function CustomerService_$ctor_Z749FCB85(systemService, authService) {
    return new CustomerService(systemService, authService);
}

export class StripeService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    GetStripeKey() {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return stripeServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getStripeKey());
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    CreateInactiveSubscription(selectedOrgId, subscription) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return stripeServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.createInactiveSubscription(selectedOrgId, subscription));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetProductSubscriptionDetails(selectedOrgId, product) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return stripeServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getProductSubscriptionDetails(selectedOrgId, product));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    CancelSubscription(selectedOrgId, product, subscriptionId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return stripeServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.cancelSubscription(selectedOrgId, product, subscriptionId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    MarkSubscriptionPaymentSuccessful(subscriptionId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return stripeServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.markSubscriptionPaymentSuccessful(subscriptionId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetSubscriptionUserAllocations(selectedOrgId, product, searchText, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return stripeServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.getSubscriptionUserAllocations(selectedOrgId, product, searchText, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AllocateUserToSubscription(selectedOrgId, subscriptionsToAllocate) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return stripeServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.allocateUserToSubscription(selectedOrgId, subscriptionsToAllocate));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    RemoveUserSubscription(selectedOrgId, subscriptionAllocationId, subscriptionId, userId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return stripeServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.removeUserSubscription(selectedOrgId, subscriptionAllocationId, subscriptionId, userId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    ChangeSubscriptionQty(selectedOrgId, subscriptionId, product, oldSubscriptionCount, newSubscriptionCount) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = makeSecureRemoteCall(this$.systemService, this$.authService, () => {
                const tupledArg = getTokenAndUserRole(this$.systemService);
                return stripeServiceProxy(tupledArg[0], tupledArg[1]);
            }, (x) => x.changeSubscriptionQty(selectedOrgId, subscriptionId, product, oldSubscriptionCount, newSubscriptionCount));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function StripeService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.StripeService", undefined, StripeService);
}

export function StripeService_$ctor_Z749FCB85(systemService, authService) {
    return new StripeService(systemService, authService);
}

export class VehicleService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    SearchVehicles(selectedOrgId, searchText, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = VehicleService__secureRemoteCall_Z66C11BB7(this$, (x) => x.searchVehicles(selectedOrgId, searchText, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetVehicle(selectedOrgId, vehicleId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = VehicleService__secureRemoteCall_Z66C11BB7(this$, (x) => x.getVehicle(selectedOrgId, vehicleId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetVehicleImage(selectedOrgId, vehicleId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = VehicleService__secureRemoteCall_Z66C11BB7(this$, (x) => x.getVehicleImage(selectedOrgId, vehicleId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetVehicles(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = VehicleService__secureRemoteCall_Z66C11BB7(this$, (x) => x.getVehicles(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetVehicleLogTags(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = VehicleService__secureRemoteCall_Z66C11BB7(this$, (x) => x.getVehicleLogTags(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpsertVehicle(selectedOrgId, vehicle) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = VehicleService__secureRemoteCall_Z66C11BB7(this$, (x) => x.upsertVehicle(selectedOrgId, vehicle));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetVehicleLogs(selectedOrgId, dateFrom, dateTo, vehiclesToSearchFor, vehicleLogTags, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = VehicleService__secureRemoteCall_Z66C11BB7(this$, (x) => x.getVehicleLogs(selectedOrgId, dateFrom, dateTo, vehiclesToSearchFor, vehicleLogTags, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetFuelSummary(selectedOrgId, dateFrom, dateTo) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = VehicleService__secureRemoteCall_Z66C11BB7(this$, (x) => x.getFuelSummary(selectedOrgId, dateFrom, dateTo));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetVehicleLogSummary(selectedOrgId, dateFrom, dateTo) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = VehicleService__secureRemoteCall_Z66C11BB7(this$, (x) => x.getVehicleLogSummary(selectedOrgId, dateFrom, dateTo));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function VehicleService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.VehicleService", undefined, VehicleService);
}

export function VehicleService_$ctor_Z749FCB85(systemService, authService) {
    return new VehicleService(systemService, authService);
}

export function VehicleService__secureRemoteCall_Z66C11BB7(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return vehicleServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

export class LogMasterService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    InsertVehicle(selectedOrgId, vehicle) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = LogMasterService__secureRemoteCall_421F52A9(this$, (x) => x.insertVehicle(selectedOrgId, vehicle));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function LogMasterService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.LogMasterService", undefined, LogMasterService);
}

export function LogMasterService_$ctor_Z749FCB85(systemService, authService) {
    return new LogMasterService(systemService, authService);
}

export function LogMasterService__secureRemoteCall_421F52A9(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return logMasterServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

export class UserService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    SearchUsers(selectedOrgId, userSearchDetails, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.searchUsers(selectedOrgId, userSearchDetails, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetUser(selectedOrgId, userId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.getUser(selectedOrgId, userId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetUserInviteSent(selectedOrgId, invitee, product) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.getUserInviteSent(selectedOrgId, invitee, product));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AddUserToOrganisation(selectedOrgId, user) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.addUserToOrganisation(selectedOrgId, user));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpdateUser(selectedOrgId, user, userSkillsToDelete) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.updateUser(selectedOrgId, user, userSkillsToDelete));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    InviteUser(selectedOrgId, username, product) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.inviteUser(selectedOrgId, username, product));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetInviteDetails(selectedOrgId, inviteId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.getInviteDetails(selectedOrgId, inviteId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AcceptInvite(selectedOrgId, inviteId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.acceptInvite(selectedOrgId, inviteId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetUserMemberships(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.getUserMemberships(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetUserMeasurementSystem(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.getUserMeasurementSystem(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    CheckUsernameExists(username) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.checkUsernameExists(username));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetUserReportData(responseDataFormat, selectedOrgId, skills, englishProficiencies, accommodationTypes) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.getUserReportData(responseDataFormat, selectedOrgId, skills, englishProficiencies, accommodationTypes));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    ResendTempPassword(selectedOrgId, invitee) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.resendTempPassword(selectedOrgId, invitee));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetUserImportCsv(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.getUserImportCsv(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UploadUserImportCsv(selectedOrgId, fileArrayBuffer) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.uploadUserImportCsv(selectedOrgId, fileArrayBuffer));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SendPendingImportUserEmails(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.sendPendingImportUserEmails(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetUserSubscriptionDetails(selectedOrgId, userId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = UserService__secureRemoteCall_6B912320(this$, (x) => x.getUserSubscriptionDetails(selectedOrgId, userId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function UserService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.UserService", undefined, UserService);
}

export function UserService_$ctor_Z749FCB85(systemService, authService) {
    return new UserService(systemService, authService);
}

export function UserService__secureRemoteCall_6B912320(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return userServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

export class SkillService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    SearchSkills(selectedOrgId, searchText, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = SkillService__secureRemoteCall_Z7F0761C0(this$, (x) => x.searchSkills(selectedOrgId, searchText, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetSkill(selectedOrgId, skillId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = SkillService__secureRemoteCall_Z7F0761C0(this$, (x) => x.getSkill(selectedOrgId, skillId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AddSkill(selectedOrgId, skill) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = SkillService__secureRemoteCall_Z7F0761C0(this$, (x) => x.addSkill(selectedOrgId, skill));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpdateSkill(selectedOrgId, skill) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = SkillService__secureRemoteCall_Z7F0761C0(this$, (x) => x.updateSkill(selectedOrgId, skill));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function SkillService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.SkillService", undefined, SkillService);
}

export function SkillService_$ctor_Z749FCB85(systemService, authService) {
    return new SkillService(systemService, authService);
}

export function SkillService__secureRemoteCall_Z7F0761C0(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return skillServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

export class JobService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    SearchJobs(selectedOrgId, jobSearchDetails, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.searchJobs(selectedOrgId, jobSearchDetails, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetJobAndCustomer(selectedOrgId, jobId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getJobAndCustomer(selectedOrgId, jobId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AddJob(selectedOrgId, job) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.addJob(selectedOrgId, job));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpdateJob(selectedOrgId, job) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.updateJob(selectedOrgId, job));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetJobUserSkill(selectedOrgId, jobId, jobUserSkillId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getJobUserSkill(selectedOrgId, jobId, jobUserSkillId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetAllJobUserSkills(selectedOrgId, jobId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getAllJobUserSkills(selectedOrgId, jobId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    DeleteJobUserSkill(selectedOrgId, jobId, jobUserSkillId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.deleteJobUserSkill(selectedOrgId, jobId, jobUserSkillId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AddJobUserSkill(selectedOrgId, jobUserSkill) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.addJobUserSkill(selectedOrgId, jobUserSkill));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpdateJobUserSkill(selectedOrgId, jobUserSkill) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.updateJobUserSkill(selectedOrgId, jobUserSkill));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetUsersToSelect(selectedOrgId, jobId, skillId, rating) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getUsersToSelect(selectedOrgId, jobId, skillId, rating));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AllocateSelectedUsers(selectedOrgId, jobId, jobUserSkillId, selectedUsers) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.allocateSelectedUsers(selectedOrgId, jobId, jobUserSkillId, selectedUsers));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetAllJobUserSkillAllocations(selectedOrgId, jobId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getAllJobUserSkillAllocations(selectedOrgId, jobId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    RemoveJobUserSkillAllocation(selectedOrgId, jobId, jobUserSkillAllocationId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.removeJobUserSkillAllocation(selectedOrgId, jobId, jobUserSkillAllocationId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetWorkerJobAllocations(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getWorkerJobAllocations(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetWorkforceSkills(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getWorkforceSkills(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetWorkersAtJobs(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getWorkersAtJobs(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetJobShiftScheduleOverrides(selectedOrgId, jobId, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getJobShiftScheduleOverrides(selectedOrgId, jobId, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpsertJobShiftScheduleOverride(selectedOrgId, jobShiftScheduleOverride) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.upsertJobShiftScheduleOverride(selectedOrgId, jobShiftScheduleOverride));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetUserJobOverrides(selectedOrgId, dateFrom, dateTo, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getUserJobOverrides(selectedOrgId, dateFrom, dateTo, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetActiveJobs(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getActiveJobs(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetJobsOfSiteManager(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getJobsOfSiteManager(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SaveUserJobOverrides(selectedOrgId, userJobOverridesToUpsert, userJobOverridesToDelete) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.saveUserJobOverrides(selectedOrgId, userJobOverridesToUpsert, userJobOverridesToDelete));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetSimproJob(selectedOrgId, simproJobId, simproConnectionDetailId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getSimproJob(selectedOrgId, simproJobId, simproConnectionDetailId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetSimproJobSections(selectedOrgId, simproJobId, simproConnectionDetailId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getSimproJobSections(selectedOrgId, simproJobId, simproConnectionDetailId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetSimproJobCostCentres(selectedOrgId, simproJobId, simproConnectionDetailId, sectionId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getSimproJobCostCentres(selectedOrgId, simproJobId, simproConnectionDetailId, sectionId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetSimproJobAttachmentFolders(selectedOrgId, simproJobId, simproConnectionDetailId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getSimproJobAttachmentFolders(selectedOrgId, simproJobId, simproConnectionDetailId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetSimproContractorWorkOrderDetails(selectedOrgId, jobId, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getSimproContractorWorkOrderDetails(selectedOrgId, jobId, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetJobShiftTemplateNames(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getJobShiftTemplateNames(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SearchJobShiftTemplates(selectedOrgId, searchText, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.searchJobShiftTemplates(selectedOrgId, searchText, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetJobShiftTemplate(selectedOrgId, jobShiftTemplateId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getJobShiftTemplate(selectedOrgId, jobShiftTemplateId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AddJobShiftTemplate(selectedOrgId, jobShiftTemplate) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.addJobShiftTemplate(selectedOrgId, jobShiftTemplate));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpdateJobShiftTemplate(selectedOrgId, jobShiftTemplate) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.updateJobShiftTemplate(selectedOrgId, jobShiftTemplate));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetJobShiftDefaultTemplates(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getJobShiftDefaultTemplates(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetWorkerJobs(selectedOrgId, jobIds) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getWorkerJobs(selectedOrgId, jobIds));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetShiftJobImageDetails(selectedOrgId, jobId, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getShiftJobImageDetails(selectedOrgId, jobId, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetShiftJobImageThumbnail(selectedOrgId, shiftJobImageId) {
        const this$ = this;
        return singleton.Delay(() => {
            let asyncResult_1;
            const input = appFetch(this$.systemService, "GET", `${baseUrl}Job/GetShiftJobImageThumbnail?selectedOrgId=${selectedOrgId}&shiftJobImageId=${shiftJobImageId}`, empty());
            asyncResult_1 = singleton.Bind(input, (x$0027) => {
                let value;
                const input_2 = x$0027;
                value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [appFetchResponseToResult(input_2.fields[0])])));
                return singleton.Return(value);
            });
            return singleton.Bind(asyncResult_1, (input_1_2) => {
                const input_6 = input_1_2;
                if (input_6.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                }
                else {
                    return input_6.fields[0];
                }
            });
        });
    }
    GetShiftJobImage(selectedOrgId, shiftJobImageId) {
        const this$ = this;
        return singleton.Delay(() => {
            let asyncResult_1;
            const input = appFetch(this$.systemService, "GET", `${baseUrl}Job/GetShiftJobImage?selectedOrgId=${selectedOrgId}&shiftJobImageId=${shiftJobImageId}`, empty());
            asyncResult_1 = singleton.Bind(input, (x$0027) => {
                let value;
                const input_2 = x$0027;
                value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [appFetchResponseToResult(input_2.fields[0])])));
                return singleton.Return(value);
            });
            return singleton.Bind(asyncResult_1, (input_1_2) => {
                const input_6 = input_1_2;
                if (input_6.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                }
                else {
                    return input_6.fields[0];
                }
            });
        });
    }
    GetQAUploadLogs(selectedOrgId, jobId, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = JobService__secureRemoteCall_Z311DBB2A(this$, (x) => x.getQAUploadLogs(selectedOrgId, jobId, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetQAUploadLogAttachment(selectedOrgId, qaUploadLogId) {
        const this$ = this;
        return singleton.Delay(() => {
            let asyncResult_1;
            const input = appFetch(this$.systemService, "GET", `${baseUrl}Job/GetQAUploadLogAttachment?selectedOrgId=${selectedOrgId}&qaUploadLogId=${qaUploadLogId}`, empty());
            asyncResult_1 = singleton.Bind(input, (x$0027) => {
                let value;
                const input_2 = x$0027;
                value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [appFetchResponseToResult(input_2.fields[0])])));
                return singleton.Return(value);
            });
            return singleton.Bind(asyncResult_1, (input_1_2) => {
                const input_6 = input_1_2;
                if (input_6.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                }
                else {
                    return input_6.fields[0];
                }
            });
        });
    }
}

export function JobService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.JobService", undefined, JobService);
}

export function JobService_$ctor_Z749FCB85(systemService, authService) {
    return new JobService(systemService, authService);
}

export function JobService__secureRemoteCall_Z311DBB2A(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return jobServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

export class ShiftService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    SearchTimesheets(selectedOrgId, searchText, statuses, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.searchTimesheets(selectedOrgId, searchText, statuses, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GenerateTimesheetRun(selectedOrgId, dateRange, jobs) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.generateTimesheetRun(selectedOrgId, dateRange, jobs));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetTimesheetRun(selectedOrgId, timesheetRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getTimesheetRun(selectedOrgId, timesheetRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetTimesheets(selectedOrgId, timesheetRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getTimesheets(selectedOrgId, timesheetRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    ApproveTimesheets(selectedOrgId, timesheets) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.approveTimesheets(selectedOrgId, timesheets));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UndoApprovalForTimesheets(selectedOrgId, timesheetRunId, timesheets) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.undoApprovalForTimesheets(selectedOrgId, timesheetRunId, timesheets));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    ApproveTimesheetRun(selectedOrgId, timesheetRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.approveTimesheetRun(selectedOrgId, timesheetRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    CancelTimesheetRun(selectedOrgId, timesheetRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.cancelTimesheetRun(selectedOrgId, timesheetRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SearchTimesheetPayments(selectedOrgId, searchText, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.searchPayments(selectedOrgId, searchText, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GeneratePaymentRun(selectedOrgId, payPeriod, timesheetRunIds) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.generatePaymentRun(selectedOrgId, payPeriod, timesheetRunIds));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetPaymentRun(selectedOrgId, paymentRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getPaymentRun(selectedOrgId, paymentRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetPayments(selectedOrgId, paymentRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getPayments(selectedOrgId, paymentRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    ExportSmartlyCsv(selectedOrgId, paymentRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.exportSmartlyCsv(selectedOrgId, paymentRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    MarkPaymentRunAsPaid(selectedOrgId, paymentRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.markPaymentRunAsPaid(selectedOrgId, paymentRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    CancelPaymentRun(selectedOrgId, paymentRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.cancelPaymentRun(selectedOrgId, paymentRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SearchShifts(selectedOrgId, shiftSearchDetails, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.searchShifts(selectedOrgId, shiftSearchDetails, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetShift(selectedOrgId, shiftId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getShift(selectedOrgId, shiftId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SaveTimesheet(selectedOrgId, timesheet) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.saveTimesheet(selectedOrgId, timesheet));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SearchTimesheetsForPaymentRun(selectedOrgId, payPeriod) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.searchTimesheetsForPaymentRun(selectedOrgId, payPeriod));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetTimesheetsForUser(selectedOrgId, paymentRunId, userId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getTimesheetsForUser(selectedOrgId, paymentRunId, userId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetTimesheetsBetweenDates(selectedOrgId, dataFormat, dateFrom, dateTo) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getTimesheetsBetweenDates(selectedOrgId, dataFormat, dateFrom, dateTo));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetTimesheetReportByWorkers(selectedOrgId, dataFormat, dateFrom, dateTo) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getTimesheetReportByWorkers(selectedOrgId, dataFormat, dateFrom, dateTo));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetWorkerDurationByJob(selectedOrgId, dataFormat, dateFrom, dateTo) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getWorkerDurationByJob(selectedOrgId, dataFormat, dateFrom, dateTo));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetSimproContractWorkOrders(selectedOrgId, paymentRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getSimproContractWorkOrders(selectedOrgId, paymentRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpdateShiftJob(selectedOrgId, shiftId, jobId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.updateShiftJob(selectedOrgId, shiftId, jobId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetShiftUsersBetweenDates(selectedOrgId, responseDataFormat, dateFrom, dateTo) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getShiftUsersBetweenDates(selectedOrgId, responseDataFormat, dateFrom, dateTo));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetAllShiftLogRecords(selectedOrgId, shiftLogId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getAllShiftLogRecords(selectedOrgId, shiftLogId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetPaymentReport(selectedOrgId, paymentId, dataFormat) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getPaymentReport(selectedOrgId, paymentId, dataFormat));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetPaymentReportPdf(selectedOrgId, paymentId) {
        const this$ = this;
        return singleton.Delay(() => {
            let asyncResult_1;
            const input = appFetch(this$.systemService, "GET", `${baseUrl}Shift/GeneratePaymentPdf?selectedOrgId=${selectedOrgId}&paymentRunId=${paymentId}`, empty());
            asyncResult_1 = singleton.Bind(input, (x$0027) => {
                let value;
                const input_2 = x$0027;
                value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [appFetchResponseToResult(input_2.fields[0])])));
                return singleton.Return(value);
            });
            return singleton.Bind(asyncResult_1, (input_1_2) => {
                const input_6 = input_1_2;
                if (input_6.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                }
                else {
                    return input_6.fields[0];
                }
            });
        });
    }
    GetTimesheetRunPdf(selectedOrgId, timesheetReportGroupedBy, timesheetRunId, jobId) {
        const this$ = this;
        return singleton.Delay(() => {
            let asyncResult_1;
            const input = appFetch(this$.systemService, "GET", `${baseUrl}Shift/GenerateTimesheetRunPdf?selectedOrgId=${selectedOrgId}&timesheetReportGroupedBy=${Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(TimesheetReportGroupedByModule_toString(timesheetReportGroupedBy))}&timesheetRunId=${timesheetRunId}&jobId=${(jobId == null) ? "" : Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(jobId)}`, empty());
            asyncResult_1 = singleton.Bind(input, (x$0027) => {
                let value_2;
                const input_2 = x$0027;
                value_2 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [appFetchResponseToResult(input_2.fields[0])])));
                return singleton.Return(value_2);
            });
            return singleton.Bind(asyncResult_1, (input_1_2) => {
                const input_6 = input_1_2;
                if (input_6.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                }
                else {
                    return input_6.fields[0];
                }
            });
        });
    }
    GetTimesheetsPdf(selectedOrgId, timesheetReportGroupedBy, jobIds, dateRange) {
        const this$ = this;
        return singleton.Delay(() => {
            const x_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getTimesheetsPdf(selectedOrgId, timesheetReportGroupedBy, jobIds, dateRange));
            return singleton.Bind(x_1, (x$0027) => {
                let value;
                const input_2 = x$0027;
                value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [input_2.fields[0]])));
                return singleton.Return(value);
            });
        });
    }
    GetTimesheetPdf(selectedOrgId, jobIds, dateRange, userId) {
        const this$ = this;
        return singleton.Delay(() => {
            const x_1 = ShiftService__secureRemoteCall_5369CF91(this$, (x) => x.getTimesheetPdf(selectedOrgId, jobIds, dateRange, userId));
            return singleton.Bind(x_1, (x$0027) => {
                let value;
                const input_2 = x$0027;
                value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [input_2.fields[0]])));
                return singleton.Return(value);
            });
        });
    }
    GetShiftPdf(selectedOrgId, shiftId) {
        const this$ = this;
        return singleton.Delay(() => {
            let asyncResult_1;
            const input = appFetch(this$.systemService, "GET", `${baseUrl}Shift/GetShiftPdf?selectedOrgId=${selectedOrgId}&shiftId=${shiftId}`, empty());
            asyncResult_1 = singleton.Bind(input, (x$0027) => {
                let value;
                const input_2 = x$0027;
                value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [appFetchResponseToResult(input_2.fields[0])])));
                return singleton.Return(value);
            });
            return singleton.Bind(asyncResult_1, (input_1_2) => {
                const input_6 = input_1_2;
                if (input_6.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                }
                else {
                    return input_6.fields[0];
                }
            });
        });
    }
}

export function ShiftService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.ShiftService", undefined, ShiftService);
}

export function ShiftService_$ctor_Z749FCB85(systemService, authService) {
    return new ShiftService(systemService, authService);
}

export function ShiftService__secureRemoteCall_5369CF91(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return shiftServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

export class LeavesService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    SearchLeaves(selectedOrgId, leaveSearchDetails, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = LeavesService__secureRemoteCall_47C12CD9(this$, (x) => x.searchLeaves(selectedOrgId, leaveSearchDetails, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetLeaveRequest(selectedOrgId, leaveId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = LeavesService__secureRemoteCall_47C12CD9(this$, (x) => x.getLeaveRequest(selectedOrgId, leaveId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    ApproveLeaveRequest(selectedOrgId, leaveRequest) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = LeavesService__secureRemoteCall_47C12CD9(this$, (x) => x.approveLeaveRequest(selectedOrgId, leaveRequest));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    RejectLeaveRequest(selectedOrgId, leaveRequest) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = LeavesService__secureRemoteCall_47C12CD9(this$, (x) => x.rejectLeaveRequest(selectedOrgId, leaveRequest));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetLeaves(selectedOrgId, startDate, endDate) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = LeavesService__secureRemoteCall_47C12CD9(this$, (x) => x.getLeaves(selectedOrgId, startDate, endDate));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetLeavePdf(selectedOrgId, leaveId) {
        const this$ = this;
        return singleton.Delay(() => {
            const x_1 = LeavesService__secureRemoteCall_47C12CD9(this$, (x) => x.getLeavePdf(selectedOrgId, leaveId));
            return singleton.Bind(x_1, (x$0027) => {
                let value;
                const input_2 = x$0027;
                value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [input_2.fields[0]])));
                return singleton.Return(value);
            });
        });
    }
    SaveLeaveRequest(selectedOrgId, leaveRequest) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = LeavesService__secureRemoteCall_47C12CD9(this$, (x) => x.saveLeaveRequest(selectedOrgId, leaveRequest));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function LeavesService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.LeavesService", undefined, LeavesService);
}

export function LeavesService_$ctor_Z749FCB85(systemService, authService) {
    return new LeavesService(systemService, authService);
}

export function LeavesService__secureRemoteCall_47C12CD9(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return leavesServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

export class ExpenseService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    SearchExpenses(selectedOrgId, expenseSearchDetails, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ExpenseService__secureRemoteCall_6036981(this$, (x) => x.searchExpenses(selectedOrgId, expenseSearchDetails, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetExpense(selectedOrgId, expenseId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ExpenseService__secureRemoteCall_6036981(this$, (x) => x.getExpense(selectedOrgId, expenseId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetExpenseAttachment(selectedOrgId, expenseId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ExpenseService__secureRemoteCall_6036981(this$, (x) => x.getExpenseAttachment(selectedOrgId, expenseId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpdateExpense(selectedOrgId, expense) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ExpenseService__secureRemoteCall_6036981(this$, (x) => x.updateExpense(selectedOrgId, expense));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    ApproveExpense(selectedOrgId, expense) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ExpenseService__secureRemoteCall_6036981(this$, (x) => x.approveExpense(selectedOrgId, expense));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    RejectExpense(selectedOrgId, expense) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = ExpenseService__secureRemoteCall_6036981(this$, (x) => x.rejectExpense(selectedOrgId, expense));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function ExpenseService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.ExpenseService", undefined, ExpenseService);
}

export function ExpenseService_$ctor_Z749FCB85(systemService, authService) {
    return new ExpenseService(systemService, authService);
}

export function ExpenseService__secureRemoteCall_6036981(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return expenseServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

export class XeroService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    SaveXeroCode(selectedOrgId, code) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = XeroService__secureRemoteCall_Z310CF1EF(this$, (x) => x.saveXeroCode(selectedOrgId, code));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetXeroCustomers(selectedOrgId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = XeroService__secureRemoteCall_Z310CF1EF(this$, (x) => x.getXeroCustomers(selectedOrgId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SaveXeroSettings(selectedOrgId, invoiceItemAccountCode, invoiceItemTaxType) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = XeroService__secureRemoteCall_Z310CF1EF(this$, (x) => x.saveXeroSettings(selectedOrgId, invoiceItemAccountCode, invoiceItemTaxType));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function XeroService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.XeroService", undefined, XeroService);
}

export function XeroService_$ctor_Z749FCB85(systemService, authService) {
    return new XeroService(systemService, authService);
}

export function XeroService__secureRemoteCall_Z310CF1EF(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return xeroServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

export class InvoiceService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    SearchInvoiceRuns(selectedOrgId, searchText, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = InvoiceService__secureRemoteCall_28E1F760(this$, (x) => x.searchInvoiceRuns(selectedOrgId, searchText, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GenerateInvoiceRun(selectedOrgId, invoiceGenerationMethod, selectedJobs) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = InvoiceService__secureRemoteCall_28E1F760(this$, (x) => x.generateInvoiceRun(selectedOrgId, invoiceGenerationMethod, selectedJobs));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetInvoiceRunData(selectedOrgId, invoiceRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = InvoiceService__secureRemoteCall_28E1F760(this$, (x) => x.getInvoiceRunData(selectedOrgId, invoiceRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    ApproveInvoiceRun(selectedOrgId, invoiceRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = InvoiceService__secureRemoteCall_28E1F760(this$, (x) => x.approveInvoiceRun(selectedOrgId, invoiceRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    CancelInvoiceRun(selectedOrgId, invoiceRunId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = InvoiceService__secureRemoteCall_28E1F760(this$, (x) => x.cancelInvoiceRun(selectedOrgId, invoiceRunId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetInvoiceRunItemsForJob(selectedOrgId, jobId, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = InvoiceService__secureRemoteCall_28E1F760(this$, (x) => x.getInvoiceRunItemsForJob(selectedOrgId, jobId, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function InvoiceService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.InvoiceService", undefined, InvoiceService);
}

export function InvoiceService_$ctor_Z749FCB85(systemService, authService) {
    return new InvoiceService(systemService, authService);
}

export function InvoiceService__secureRemoteCall_28E1F760(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return invoiceServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

export class HolidayService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    SearchHolidays(selectedOrgId, searchText, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = HolidayService__secureRemoteCall_Z598595D1(this$, (x) => x.searchHolidays(selectedOrgId, searchText, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetHoliday(selectedOrgId, holidayId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = HolidayService__secureRemoteCall_Z598595D1(this$, (x) => x.getHoliday(selectedOrgId, holidayId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpsertHoliday(selectedOrgId, holiday) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = HolidayService__secureRemoteCall_Z598595D1(this$, (x) => x.upsertHoliday(selectedOrgId, holiday));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetHolidays(selectedOrgId, startDate, endDate) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = HolidayService__secureRemoteCall_Z598595D1(this$, (x) => x.getHolidays(selectedOrgId, startDate, endDate));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function HolidayService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.HolidayService", undefined, HolidayService);
}

export function HolidayService_$ctor_Z749FCB85(systemService, authService) {
    return new HolidayService(systemService, authService);
}

export function HolidayService__secureRemoteCall_Z598595D1(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return holidayServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

export class SimproService {
    constructor(systemService, authService) {
        this.systemService = systemService;
        this.authService = authService;
    }
    GetSimproSetupDetails(selectedOrgId, simproSetupDetail) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = SimproService__secureRemoteCall_356A944B(this$, (x) => x.getSimproSetupDetails(selectedOrgId, simproSetupDetail));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    SearchConnectionDetails(selectedOrgId, searchText, pagination) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = SimproService__secureRemoteCall_356A944B(this$, (x) => x.searchConnectionDetails(selectedOrgId, searchText, pagination));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    GetSimproConnectionDetail(selectedOrgId, simproConnectionDetailId) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = SimproService__secureRemoteCall_356A944B(this$, (x) => x.getSimproConnectionDetail(selectedOrgId, simproConnectionDetailId));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    AddSimproConnectionDetail(selectedOrgId, simproConnectionDetail) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = SimproService__secureRemoteCall_356A944B(this$, (x) => x.addSimproConnectionDetail(selectedOrgId, simproConnectionDetail));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
    UpdateSimproConnectionDetail(selectedOrgId, simproConnectionDetail) {
        const this$ = this;
        return singleton.Delay(() => {
            const asyncResult_1 = SimproService__secureRemoteCall_356A944B(this$, (x) => x.updateSimproConnectionDetail(selectedOrgId, simproConnectionDetail));
            return singleton.Bind(asyncResult_1, (input_1) => {
                const input_2 = input_1;
                if (input_2.tag === 1) {
                    return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                }
                else {
                    return singleton.Return(input_2.fields[0]);
                }
            });
        });
    }
}

export function SimproService_$reflection() {
    return class_type("Infrastructure.WebApiService.AuthService.SimproService", undefined, SimproService);
}

export function SimproService_$ctor_Z749FCB85(systemService, authService) {
    return new SimproService(systemService, authService);
}

export function SimproService__secureRemoteCall_356A944B(this$, f) {
    return makeSecureRemoteCall(this$.systemService, this$.authService, () => {
        const tupledArg = getTokenAndUserRole(this$.systemService);
        return simproServiceProxy(tupledArg[0], tupledArg[1]);
    }, f);
}

