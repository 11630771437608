import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { ByteArray_ofArrayBuffer } from "../Util.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { DocumentDtoModule_ofDocument, DocumentRawDtoModule_ofDocument, DocumentDtoModule_toDocument, DocumentTagDtoModule_ofDocumentTag, DocumentTagDtoModule_toDocumentTag } from "../../../Logos.Shared/Domain/Dto/Document.fs.js";

export function scanBankStatement(documentService, selectedOrgId, filename, fileArrayBuffer) {
    return singleton.Delay(() => {
        const file = ByteArray_ofArrayBuffer(fileArrayBuffer);
        return documentService.ScanBankStatement(selectedOrgId, filename, file);
    });
}

export function getUnprocessedScans(documentService) {
    return singleton.Delay(() => documentService.GetUnprocessedScans());
}

export function deleteSelectedScans(documentService, selectedScanResults) {
    return singleton.Delay(() => documentService.DeleteSelectedScans(selectedScanResults));
}

export function searchDocumentTags(documentService, selectedOrgId, documentTagSearchDetails, pagination) {
    return singleton.Delay(() => {
        const input = documentService.SearchDocumentTags(selectedOrgId, documentTagSearchDetails, pagination);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(DocumentTagDtoModule_toDocumentTag, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function addDocumentTag(documentService, selectedOrgId, documentTag) {
    return singleton.Delay(() => {
        const documentTag_1 = DocumentTagDtoModule_ofDocumentTag(documentTag);
        return documentService.AddDocumentTag(selectedOrgId, documentTag_1);
    });
}

export function updateDocumentTag(documentService, selectedOrgId, documentTag) {
    return singleton.Delay(() => {
        const documentTag_1 = DocumentTagDtoModule_ofDocumentTag(documentTag);
        return documentService.UpdateDocumentTag(selectedOrgId, documentTag_1);
    });
}

export function getDocumentTag(documentService, selectedOrgId, documentId) {
    return singleton.Delay(() => {
        const input_2 = documentService.GetDocumentTag(selectedOrgId, documentId);
        return singleton.Bind(input_2, (x$0027) => {
            let input_1;
            let value;
            const input_4 = x$0027;
            value = ((input_4.tag === 1) ? (new FSharpResult$2(1, [input_4.fields[0]])) : (new FSharpResult$2(0, [(input_1 = input_4.fields[0], (input_1 == null) ? undefined : DocumentTagDtoModule_toDocumentTag(input_1))])));
            return singleton.Return(value);
        });
    });
}

export function getDocumentTags(documentService, selectedOrgId) {
    return singleton.Delay(() => {
        const input = documentService.GetDocumentTags(selectedOrgId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(DocumentTagDtoModule_toDocumentTag, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function searchDocuments(documentService, selectedOrgId, documentSearchDetails, pagination) {
    return singleton.Delay(() => {
        const input = documentService.SearchDocuments(selectedOrgId, documentSearchDetails, pagination);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(DocumentDtoModule_toDocument, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getDocument(documentService, selectedOrgId, documentId) {
    return singleton.Delay(() => {
        const input_2 = documentService.GetDocument(selectedOrgId, documentId);
        return singleton.Bind(input_2, (x$0027) => {
            let input_1;
            let value;
            const input_4 = x$0027;
            value = ((input_4.tag === 1) ? (new FSharpResult$2(1, [input_4.fields[0]])) : (new FSharpResult$2(0, [(input_1 = input_4.fields[0], (input_1 == null) ? undefined : DocumentDtoModule_toDocument(input_1))])));
            return singleton.Return(value);
        });
    });
}

export function getDocumentFile(documentService, selectedOrgId, documentId) {
    return singleton.Delay(() => documentService.GetDocumentFile(selectedOrgId, documentId));
}

export function addDocument(documentService, selectedOrgId, document$, documentFile) {
    return singleton.Delay(() => {
        const document$_1 = DocumentRawDtoModule_ofDocument(document$);
        return documentService.AddDocument(selectedOrgId, document$_1, documentFile);
    });
}

export function updateDocument(documentService, selectedOrgId, document$, documentFile) {
    return singleton.Delay(() => {
        const document$_1 = DocumentDtoModule_ofDocument(document$);
        return documentService.UpdateDocument(selectedOrgId, document$_1, documentFile);
    });
}

export function deleteDocument(documentService, selectedOrgId, documentId) {
    return singleton.Delay(() => documentService.DeleteDocument(selectedOrgId, documentId));
}

